import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { GenericLocationExtractor } from '../components/LocationExtractor';


export function ToolLocationExctractor() {

  const i18n = RefLanguage()

  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} />

        {/* Available Tools*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1.5vh" }}>{i18n.t("locationExtractor")}</CustomText>
        <GenericLocationExtractor />



      </div>
    </div>
  );
}

export default ToolLocationExctractor


