import Header from '../components/Header';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { RefHasGovees, RefHasKestrels, RefHasMXHobo, RefGoovePositionIds, RefKestrelPositionIds, RefMXHobosPositionIds } from '../hooks/BundleDeviceHooks';
import { useEffect, useRef, useState } from 'react';
import { getATOField, RefKeysOfATOSet, restartDataCollection, setStoredValue } from '../hooks/StoreHooks';
import { showNotification } from '../utils/generalFunctions';
import CustomButton from '../elements/CustomButton';
import { SimpleCopyField } from '../elements/SimpleStoreCopyField';
import ParticipantIdUpdater from '../components/ParticipantIdUpdater';
import CheckboxGroup from '../elements/CheckboxGroup';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import { SensorStatusSetViewer } from '../components/SensorSetViewer';
import WearableStatusViewer from '../components/WearableStatusViewer';
import PhoneStatusViewer from '../components/PhoneStatusViewer';
import PerceptualSurveyStatusViewer from '../components/PerceptualSurveyStatusViewer';
import CollectionReport from '../components/CollectionReport';
import CompletionStatusText from '../elements/CompletionStatusText';


export function BundleCollectData() {

  const i18n = RefLanguage()

  // Current Page
  const setCurrentPage = RefSetCurrentPage()

  const hasGovees = RefHasGovees()
  const goveePositionIds = RefGoovePositionIds()


  const hasKestrel = RefHasKestrels()
  const kestrelPositionIds = RefKestrelPositionIds()

  const hasMXHobo = RefHasMXHobo()
  const mxHobosPositionIds = RefMXHobosPositionIds()

  const restartProcess = () => {
    restartDataCollection()
    showNotification(i18n.t("dataCollectionRestarted"))

  }

  const needsToUpdate = useRef(getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID) === libCon.MISSING)


  const [allCheckboxes, setAllCheckboxes] = useState([...locCon.DATA_COLLECT_CHECKBOXES])


  const keys = RefKeysOfATOSet()

  useEffect(() => {

    let newCheckboxes = [...locCon.DATA_COLLECT_CHECKBOXES, ...keys.map(positionId => locCon.getASCheckBoxId(positionId))]


    if (hasGovees)
      newCheckboxes = [...newCheckboxes, ...locCon.BUNDLE_SET_UP_GOVEE_CHECKBOXES]

    if (hasKestrel)
      newCheckboxes = [...newCheckboxes, ...locCon.BUNDLE_SET_UP_KESTREL_CHECKBOXES]

    setAllCheckboxes(newCheckboxes)

  }, [keys, hasGovees, hasKestrel])


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>


        <Header long={false} />
        <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("collectData")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToCollect")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

        <div className="horizontalLine" />



        {/* Update SEWA ID */}
        {
          needsToUpdate.current === true
            ? <div>
              <ParticipantIdUpdater />
              <div className="horizontalLine" />
            </div>
            : <div></div>
        }


        {/* Govee Sensor Data */}
        {
          hasGovees === true
            ? <div className='verticalSection'>
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("GoveeSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("GoveeSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.GS_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.GD_SYNC_DEVICES, [libCon.TEXT]: i18n.t("syncDevices") },
                { [libCon.ID]: locCon.GD_SEND_EMAIL, [libCon.TEXT]: i18n.t("sendEmail") }

                ]} />

              <SensorStatusSetViewer filterKeys={goveePositionIds} />

              <div className="horizontalLine" />
            </div>
            : <div></div>
        }

        {/* Kestrel Sensor Data */}
        {
          hasKestrel === true
            ? <div className="verticalSection">
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("KestrelSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("KestrelSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.KS_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.KD_SYNC_DEVICES, [libCon.TEXT]: i18n.t("syncDevice") },
                {
                  [libCon.ID]: locCon.KD_SEND_EMAIL,
                  [libCon.TEXT]: i18n.t("sendEmail"),
                  [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("sensorInbox")} value={libCon.SENSOR_INBOX_EMAIL} />
                }

                ]} />

              <SensorStatusSetViewer filterKeys={kestrelPositionIds} />

              <div className="horizontalLine" />
            </div>
            : <div></div>
        }

        {/* MX Hobo Sensor Data */}
        {
          hasMXHobo === true
            ? <div className="verticalSection">
              <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("MXHoboSensorData")}</CustomText>
              <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("MXHoboSensorDataText")}</CustomText>
              <CheckboxGroup
                mainCheckbox={{ [libCon.ID]: locCon.MXHOBO_COLLECT_SENSOR_DATA, [libCon.TEXT]: i18n.t("collectSensorData") }}
                checkBoxArray={[{ [libCon.ID]: locCon.MXHOBO_SYNC_DEVICES, [libCon.TEXT]: i18n.t("connectDevice") },
                { [libCon.ID]: locCon.MXHOBO_DOWNLOAD_DATA, [libCon.TEXT]: i18n.t("downloadData") },
                {
                  [libCon.ID]: locCon.MXHOBO_SEND_EMAIL,
                  [libCon.TEXT]: i18n.t("exportAndSendEmail"),
                  [libCon.CONTAINER]: <SimpleCopyField title={i18n.t("sensorInbox")} value={libCon.SENSOR_INBOX_EMAIL} />
                }

                ]} />

              <SensorStatusSetViewer filterKeys={mxHobosPositionIds} />

              <div className="horizontalLine" />

            </div>
            : <div></div>
        }

        {/* Wearable Data */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearableData")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("wearableDataText")}</CustomText>
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.WD_SYNC_WEARABLE_DATA, [libCon.TEXT]: i18n.t("syncDevice") }} />
        <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.WD_CHECK_HEATRATE, [libCon.TEXT]: i18n.t("checkHeartrate") }} />


        <WearableStatusViewer includeTitle={false} />

        <div className="horizontalLine" />


        {/* Location Data */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationData")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("locationDataText")}</CustomText>
        <CheckboxGroup
          mainCheckbox={{ [libCon.ID]: locCon.LD_COLLECT_LOCATION_DATA, [libCon.TEXT]: i18n.t("collectLocationData") }}
          checkBoxArray={[{ [libCon.ID]: locCon.LD_EXPORT_DATA, [libCon.TEXT]: i18n.t("exportData") },
          { [libCon.ID]: locCon.LD_RESET_TRACKING, [libCon.TEXT]: i18n.t("resetTracking") },
          { [libCon.ID]: locCon.LD_TURN_ON_LOCATION, [libCon.TEXT]: i18n.t("turnOnLocation") },


          ]} />

        <PhoneStatusViewer includeTitle={false} />

        <div className="horizontalLine" />

        <div className='verticalSection'>

          <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("healthForm")}</CustomText>
          <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("perceptualSurveyInstructions")}</CustomText>
          <CheckboxGroup
            mainCheckbox={{ [libCon.ID]: locCon.PS_ADMINISTER_PERCEPTUAL_SURVEY, [libCon.TEXT]: i18n.t("administerPerceptualSurvey") }} />
          <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => { setCurrentPage(locCon.PAGE_PERCEPTUAL_SURVEY); setStoredValue(locCon.PS_ADMINISTER_PERCEPTUAL_SURVEY, true) }}>{i18n.t("administerPerceptualSurvey")}</CustomButton>

        </div>

        <PerceptualSurveyStatusViewer includeTitle={false} />
        <div className="horizontalLine" />

        <CollectionReport />
        <div className="horizontalLine" />

        <CompletionStatusText checkBoxIds={allCheckboxes} />
        <div className="horizontalLine" />


      </div>
    </div>
  );
}

export default BundleCollectData


