import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomButton from '../elements/CustomButton';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import { NewIcon } from '../elements/CustomIcons';
import { setStoredValue } from '../hooks/StoreHooks';


export function ManagerMainApp() {

  const i18n = RefLanguage()

  // Current Page
  const setCurrentPage = RefSetCurrentPage()


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={false} />


        {/* Bundle Set Up*/}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("bundles")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_SET_UP_BUNDLE)}>{i18n.t("setUpBundle")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_DEPLOY_BUNDLE)}>{i18n.t("deployBundle")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_SYNC_BUNDLE)}> <NewIcon color='var(--primary-color-5)' />{i18n.t("bundleSync")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_BUNDLE_RETRIEVE)}><NewIcon color='var(--primary-color-5)' />{i18n.t("retreiveBundle")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_MANAGE_BUNDLE)}><NewIcon color='var(--primary-color-5)' />{i18n.t("bundleManage")}</CustomButton>
        <div className={"horizontalLine"} />


        {/* Data Collection */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("data")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_COLLECT_DATA)}>{i18n.t("collectData")}</CustomButton>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_BUNDLE_STATUS)}>{i18n.t("checkBundleStatus")}</CustomButton>
        <div className={"horizontalLine"} />

        {/* Extras */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("extras")}</CustomText>
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(locCon.PAGE_TOOLS_MAIN)}>{i18n.t("toolsSetUp")}</CustomButton>

        <div className={"horizontalLine"} style={{ marginTop: "3vh" }} />
        <CustomButton type={locCon.DANGER_BUTTON} onClick={() => setStoredValue(locCon.LAST_LOG_IN, null)}>{i18n.t("exit")}</CustomButton>




      </div>
    </div>
  );
}

export default ManagerMainApp


// const styles = {
// };
