import { useEffect, useState } from "react";

export const RefIsInstalled = () => {

    const [isInstalled, setIsInstalled] = useState(() => window.matchMedia('(display-mode: standalone)').matches); // Initial default mode


    useEffect(() => {
        // Check the initial display-mode
        const checkDisplayMode = () => {
            setIsInstalled(window.matchMedia('(display-mode: standalone)').matches)
        };

        // Run the check once on component mount
        checkDisplayMode();

        // Listen for changes in display-mode
        const standaloneMatcher = window.matchMedia('(display-mode: standalone)');

        // Add event listeners for changes
        const handleChange = () => checkDisplayMode();
        standaloneMatcher.addEventListener('change', handleChange);

        // Cleanup listeners on component unmount
        return () => {
            standaloneMatcher.removeEventListener('change', handleChange);
        };
    }, []);

    return isInstalled
}