import React from 'react'
import CustomText from './CustomText'
import { ExportOutlined } from '@ant-design/icons'
import { goToThirdPartyLink } from '../community-hats-js-library/utils/generalFunctions'
import * as locCon from "../LocalConstants"


export function Link({ title, url }) {
    return (
        <div className='horizontalSectionCenter'>

            <CustomText type={locCon.ELEMENT_TEXT} >{title}</CustomText>
            <ExportOutlined style={{ marginLeft: "1vh", color: "var(--primary-color-5)", fontSize: 30 }} onClick={() => goToThirdPartyLink(url)} />

        </div>
    )
}
