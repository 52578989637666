// Health functions
import * as locCon from '../LocalConstants';
import * as libCon from '../community-hats-js-library/Constants';
import { getATOField, getATOId, getStoredValue, setStoredValue } from '../hooks/StoreHooks';
import { uploadToBucket } from '../community-hats-js-library/utils/AWSFunctions';
import { formatExport, formatISOCustom } from '../community-hats-js-library/utils/dateFunctions';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';



export const exportHealthFormAnswers = async () => {

    setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_LOADING)

    // Builds
    const toUpload = buildPerceptualSurveyExport()
    const fileName = buildPerceptualSurveyFilename()

    let sewaId = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
    if (isNullOrUndefined(sewaId))
        sewaId = libCon.MISSING

    // Exports
    const reponse = await uploadToBucket(libCon.PERCEPTUAL_SURVEY_FOLDER, sewaId, fileName, toUpload)

    if (reponse === libCon.OK)
        setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_OK)
    else
        setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_ERROR)

    return reponse

}

export const buildPerceptualSurveyExport = () => {

    // Configuration
    const healthFormStructure = libCon.LATEST_PERCEPTUAL_SURVEY

    if (healthFormStructure === null || healthFormStructure === undefined)
        return null

    // Answers
    const answers = healthFormStructure[libCon.SURVEY_STRUCTURE].reduce((res, q) => { return Object.assign(res, { [q[libCon.SURVEY_QUESTION_ID]]: getStoredValue(encodeHealthFormId(q[libCon.SURVEY_QUESTION_ID])) }) }, {})


    let sewaId = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
    if (isNullOrUndefined(sewaId))
        sewaId = libCon.MISSING

    // Meta
    let meta = {
        ...healthFormStructure[libCon.SURVEY_METADATA],
        [libCon.SURVEY_EXPORT_PARTICIPANT_ID]: getATOId(locCon.AT_OBJECT_PARTICIPANT),
        [libCon.SURVEY_EXPORT_SEWA_ID]: getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID),
        [libCon.SURVEY_EXPORT_PHONE_SERIAL]: getATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL),
        [libCon.SURVEY_EXPORT_DATETIME]: formatISOCustom(new Date()),
        [libCon.SURVEY_EXPORT_LOCALE]: getStoredValue(locCon.STORAGE_CURRENT_LOCAL)
    }

    const response = {
        [libCon.SURVEY_EXPORT_CONFIGURATION]: healthFormStructure,
        [libCon.SURVEY_EXPORT_ANSWERS]: answers,
        [libCon.SURVEY_METADATA]: meta
    }

    return (response)


}

export const buildPerceptualSurveyFilename = () => {

    let sewa_id = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
    if (sewa_id === null)
        sewa_id = libCon.MISSING

    return (`${sewa_id}__${formatExport(new Date())}.json`)

}



export const encodeHealthFormId = (id) => {
    return (`${libCon.SURVEY_PREFIX}${id}`)
}

export const decodeHealthFormId = (id) => {
    if (id === null || id === undefined)
        return id
    return (`${id}`.replace(libCon.SURVEY_PREFIX, ""))
}