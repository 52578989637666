import React, { useEffect, useState } from 'react'
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import * as locGenFun from "../utils/generalFunctions";
import { formatDistanceToNow } from 'date-fns';
import { CopyOutlined } from '@ant-design/icons';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { RefLanguage } from '../hooks/LanguageHooks';
import { getLatestGoveeActivationCodes } from '../community-hats-js-library/utils/airtableFunctions';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { Typography } from 'antd';
import CustomSpin from '../elements/CustomSpin';

const { Text } = Typography;

const Row = ({ date, code, onCopyCleanUp = () => true }) => {


  return (
    <div style={styles.row}>
      <Text style={styles.cellText}>{formatDistanceToNow(new Date(date), { addSuffix: true })}</Text>
      <Text style={styles.cellText}>{code}</Text>
      <div style={styles.cellText}>
        <CopyOutlined name="copy" style={{ color: "var(--primary-color-5)", fontSize: 24 }} onClick={() => { locGenFun.copyToClipboard(code, "Code copied to Clipboard"); onCopyCleanUp() }} />
      </div>
    </div>
  )

}



function ActivationCodeLoader({ onCopyCleanUp = () => true }) {


  const i18n = RefLanguage()

  const [records, setRecords] = useState([])

  const [updatingRecords, setUpdatingRecords] = useState(false)


  const refreshRecords = async () => {

    setUpdatingRecords(true)


    let [code, newRecords] = await getLatestGoveeActivationCodes()

    if (code === libCon.OK)
      setRecords(newRecords)
    else
      showErrorDialogByCode(code)



    setUpdatingRecords(false)


  }



  useEffect(() => {
    refreshRecords()
  }, [])



  return (
    <div>
      <div className='verticalSection'>
        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("goveeActivationCode")}</CustomText>
      </div>

      <div style={styles.row}>
        <CustomText style={{ ...styles.cellText, fontSize: 20 }}>Received</CustomText>
        <CustomText style={{ ...styles.cellText, fontSize: 20 }}>Code</CustomText>
        <CustomText style={{ ...styles.cellText, fontSize: 20 }}>Copy</CustomText>
      </div>
      {
        records.map((r, i) =>
          <Row key={i} date={r[libCon.ATF_DATE_CREATED]} code={r[libCon.ATF_ACTIVATION_CODE]} onCopyCleanUp={onCopyCleanUp} />
        )

      }
      <div className='verticalSection' style={{ marginTop: 20 }}>
        {
          updatingRecords === true
            ? <CustomSpin type={locCon.BUTTON_SPINNER} />
            : <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomButton>
        }
      </div>

    </div>
  )
}

export default ActivationCodeLoader



const styles = {
  title: {
    textAlign: "center",
    fontSize: 22,
    marginBottom: 3,
    marginTop: 3,
  },
  subTitle: {
    textAlign: "center",
    fontSize: 18,
    marginBottom: 3,
    marginTop: 3,
  },
  row:
  {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-evenly",
    marginBottom: 3
  },
  cellText:
  {
    flex: 1,
    color: "var(--primary-color-3)",
    paddingTop: 6,
    textAlign: "center",
    fontSize: 16,
    width: "30%"
  }
};



