import * as locCon from '../LocalConstants';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomText from '../elements/CustomText';
import { useState } from 'react';
import { Input } from 'antd';
import CustomButton from '../elements/CustomButton';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { setStoredValue } from '../hooks/StoreHooks';
import { formatISO } from 'date-fns';
import LoadingComponent from '../components/LoadingComponent';


export function Authenticate() {

  const i18n = RefLanguage()

  const [password, setPassword] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const logIn = () => {

    if (!isNullOrUndefined(password) && password.toUpperCase() === locCon.PASSWORD.toUpperCase()) {


      setIsLoading(true)
      setStoredValue(locCon.LAST_LOG_IN, formatISO(new Date()))
      window.location.reload();


    }

  }


  return (
    isLoading ? <LoadingComponent />
      : <div className="mainAppContainer">
        <div className='pageContainer'>

          <div className='verticalSection' style={{ marginTop: "25vh" }}>
            <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1vh" }}>{i18n.t("enterPassword")}</CustomText>
            <Input.Password style={styles.input} onChange={(e) => setPassword(e.target.value)} value={password} placeholder={i18n.t("password")} />
            <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => logIn()}>{i18n.t("enter")}</CustomButton>

          </div>
        </div>
      </div>


  );
}


const styles = {
  input: {
    backgroundColor: "var(--primary-color-5)",
    color: "var(--primary-color-3)",
    fontSize: 16,
    marginBottom: "2vh",
    width: "100%"

  }
}





export default Authenticate


