import { useLocation, useNavigate } from 'react-router';



/**
 * Hook for navigation
 */
export function NavigationHook() {

   const location = useLocation();
   const navigate = useNavigate();

   return ([location.pathname, navigate])

}


/**
 * Ref for current page
 */
export function RefCurrentPage() {

   const location = useLocation();

   return (location.pathname)

}

/**
 * Ref for set current page
 */
export function RefSetCurrentPage() {

   const navigate = useNavigate();

   return (navigate)


}

