import React from 'react'

function LoadingComponent() {
    return (
        <div style={{ textAlign: "center", marginTop: "30vh" }}>
            <p style={{ fontFamily: "Arial, sans-serif" }}>Loading...</p>
            <img src="/adaptive-icon.png" width="200" alt="App"></img>
        </div>
    )
}

export default LoadingComponent