import { getCurrentI18n } from "../hooks/LanguageHooks";
import * as libCon from "../community-hats-js-library/Constants"
import { showError } from "./generalFunctions";

export const showErrorDialog = (message) => {
  showError(message)
}

export const showErrorDialogByCode = (code) => {

  const i18n = getCurrentI18n()
  showError(`${i18n.t(libCon.ERRORS[code][libCon.MESSAGE])} (Code: ${libCon.ERRORS[code][libCon.CODE]})`)

}