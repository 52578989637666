
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import { RefStoredBooleanValue, setStoredValue } from '../hooks/StoreHooks';
import { RefLanguage } from '../hooks/LanguageHooks';
import { useEffect } from 'react';
import TestFitibitApiButton from './TestFitibitApiButton';
import CustomText from '../elements/CustomText';
import { goToThirdPartyLink } from '../community-hats-js-library/utils/generalFunctions';
import { buildFitbitURL } from '../community-hats-js-library/utils/fitbitFunctions';
import CustomButton from '../elements/CustomButton';
import PasteFitbitAPIValues from './PasteFitbitAPIValues';
import { SimpleCopyFieldFromId } from '../elements/SimpleStoreCopyField';
import { SyncATOButton } from '../elements/SyncATOButton';



export function FitbitTokenUpdater() {

  const i18n = RefLanguage()



  const apiOk = RefStoredBooleanValue(locCon.FITBIT_API_OK)



  useEffect(() => {
    setStoredValue(locCon.FITBIT_TOKEN, null)
    setStoredValue(locCon.FITBIT_REQUEST_ID, null)
    setStoredValue(locCon.FITBIT_API_OK, false)
  }, [])



  return (
    <div className='verticalSection'>

      <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateFitbitAPI")}</CustomText>
      <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateFitbitAPIInstructions")}</CustomText>

      <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("goToAuthPage")}</CustomText>
      <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => goToThirdPartyLink(buildFitbitURL(libCon.FITBIT_FINAL_APPLICATION_ID))}>
        {i18n.t("authorizeApp")}
      </CustomButton>


      <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("pasteValues")}</CustomText>


      <PasteFitbitAPIValues />
      <SimpleCopyFieldFromId valueID={locCon.FITBIT_TOKEN} title={i18n.t("fitbitAccessToken")} />
      <SimpleCopyFieldFromId valueID={locCon.FITBIT_REQUEST_ID} title={i18n.t("fitbitRequestId")} />
      <TestFitibitApiButton />

      {
        apiOk
          ? <div className='verticalSection' style={{ marginTop: "1vh" }}>
            <SyncATOButton atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE} />
          </div>
          : <div style={{ height: "3vh" }}></div>
      }




    </div>
  );
}

export default FitbitTokenUpdater



