import React, { useEffect, useState } from 'react'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { RefLanguage } from '../hooks/LanguageHooks'
import CustomText from '../elements/CustomText'
import { SimpleStoreCopySelectionFieldFromId } from '../elements/SimpleStoreCopySelectionField'
import { getATOId, getStoredValue, RefStoredValue, setMultipleValues } from '../hooks/StoreHooks'
import { NewIcon } from '../elements/CustomIcons'
import { CustomPrimaryButtonWithDisability } from '../elements/CustomButton'
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions'
import { uploadCollectionRecord } from '../community-hats-js-library/utils/airtableFunctions'
import { showNotification } from '../utils/generalFunctions'
import { showErrorDialogByCode } from '../utils/dialogFunctions'
import CustomSpin from '../elements/CustomSpin'
import { SimpleStoreCopyTextAreaFromId } from '../elements/SimpleStoreCopyTextArea'
import CollectionStatus from '../elements/CollectionStatus'
import { differenceInMinutes, formatISO } from 'date-fns'


function CollectionReport() {

    const i18n = RefLanguage()


    // Duration
    const [options, setOptions] = useState([])
    const [optionsTextDict, setOptionsTextDict] = useState({})
    const duration = RefStoredValue(locCon.COLLECTION_REPORT_DURATION)

    // Status
    const collectionStatus = RefStoredValue(locCon.COLLECTION_REPORT_STATUS)
    const [statusOptions, setStatusOptions] = useState([])
    const [statusOptionsTextDict, setStatusOptionsTextDict] = useState({})

    // Notes
    const collectionNotes = RefStoredValue(locCon.COLLECTION_REPORT_NOTES)

    // Last Upload
    const collectionLastUpload = RefStoredValue(locCon.COLLECTION_REPORT_LAST_UPLOAD)

    const [done, setDone] = useState(false)

    useEffect(() => {

        setDone(differenceInMinutes(new Date(), new Date(collectionLastUpload)) <= 12 * 60) // 12 hours

    }, [collectionLastUpload])




    // Is Uploading
    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {

        let newOptionTextDict = {
            10: `10 ${i18n.t("minutes")}`,
            20: `20 ${i18n.t("minutes")}`,
            30: `30 ${i18n.t("minutes")}`,
            45: `45 ${i18n.t("minutes")}`,
            60: `1 ${i18n.t("hour")}`,
            90: `1.5 ${i18n.t("hours")}`,
            120: `2 ${i18n.t("hours")}`,
            150: `2.5 ${i18n.t("hours")}`,
            180: `3 ${i18n.t("hours")}`,
        }

        setOptions(Object.keys(newOptionTextDict))
        setOptionsTextDict(newOptionTextDict)


        newOptionTextDict = {
            [libCon.ATF_COMPLETE]: i18n.t("complete"),
            [libCon.ATF_INCOMPLETE]: i18n.t("incomplete"),



        }

        setStatusOptions(Object.keys(newOptionTextDict))
        setStatusOptionsTextDict(newOptionTextDict)

    }, [i18n])


    const uploadRecord = async () => {

        setIsUploading(true)

        let participantId = getATOId(locCon.AT_OBJECT_PARTICIPANT)
        let notes = getStoredValue(locCon.COLLECTION_REPORT_NOTES)


        let [status, record] = await uploadCollectionRecord(participantId, duration, collectionStatus, notes)

        if (status === libCon.OK && !isNullOrUndefined(record)) {
            showNotification(i18n.t("recordUploadedCorrectly"))
            setMultipleValues({
                [locCon.COLLECTION_REPORT_NOTES]: null,
                [locCon.COLLECTION_REPORT_DURATION]: null,
                [locCon.COLLECTION_REPORT_STATUS]: null,
                [locCon.COLLECTION_REPORT_LAST_UPLOAD]: formatISO(new Date())
            })

        }
        else
            showErrorDialogByCode(status)


        setIsUploading(false)


    }


    return (
        <div className='verticalSection'>

            <CustomText type={locCon.SECTION_TITLE_TEXT}><NewIcon /> {i18n.t("collectionReport")}</CustomText>
            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectionReportText")}</CustomText>

            <CollectionStatus status={done ? libCon.DEVICE_STATUS_OK : libCon.DEVICE_STATUS_WAITING_COLLECTION} message={done ? libCon.MESSAGE_DATA_UP_TO_DATE : libCon.MESSAGE_WAITING_FOR_COLLECTION} />


            <div className='verticalSection' style={{ marginTop: "2vh", marginBottom: "3vh" }}>
                <SimpleStoreCopySelectionFieldFromId title={i18n.t("statusOfCollection")} valueID={locCon.COLLECTION_REPORT_STATUS} options={statusOptions} optionsTextDict={statusOptionsTextDict} />
                <SimpleStoreCopySelectionFieldFromId title={i18n.t("durationOfCollection")} valueID={locCon.COLLECTION_REPORT_DURATION} options={options} optionsTextDict={optionsTextDict} />
                <SimpleStoreCopyTextAreaFromId title={i18n.t("collectionNotes")} valueID={locCon.COLLECTION_REPORT_NOTES} includeCheckbox={true} defaultValue={"Nothing to report."} checkboxText={i18n.t("nothingToReport")} />
            </div>

            {isUploading
                ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                : <CustomPrimaryButtonWithDisability onClick={() => uploadRecord()} isDisabled={isNullOrUndefined(duration) || isNullOrUndefined(collectionStatus) || isNullOrUndefined(collectionNotes)} description={i18n.t("pleaseFillInAllValues")}>{i18n.t("uploadRecord")}</CustomPrimaryButtonWithDisability>
            }

        </div>
    )
}

export default CollectionReport