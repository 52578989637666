import { BellOutlined, SunOutlined } from "@ant-design/icons"


export const NewIcon = ({ size = 24, color = "#a200ff" }) => {
    return (<SunOutlined style={{ fontSize: size, color: color }} />)
}


export const RequestIcon = ({ size = 24, color = "var(--warning-color-1)" }) => {
    return (<BellOutlined style={{ fontSize: size, color: color }} />)
}