// Functions for Airtable Object representations
import * as locCon from "../LocalConstants"
import { notification } from "antd";

export const getNewStatus = (currentStatus) => {

    let newStatus = null
    // Gets the new State
    switch (currentStatus) {
        case locCon.ATO_EMPTY:
        case locCon.ATO_ONLY_LOCAL:
            newStatus = locCon.ATO_ONLY_LOCAL
            break;

        case locCon.ATO_UP_TO_DATE:
        case locCon.ATO_NEEDS_SYNCH:
            newStatus = locCon.ATO_NEEDS_SYNCH
            break
        default:
            newStatus = currentStatus;
            break
    }

    return newStatus
}





export const copyToClipboard = async (text, message) => {
    await navigator.clipboard.writeText(`${text}`);
    showNotification(message)
};

export const getClipboardValue = async () => {
    const value = await navigator.clipboard.readText();
    return value
};

export const showNotification = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        duration: 6,
        style: {
            background: "var(--background-color-1-transparent)"
        }
    })

}

export const showError = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        style: {
            background: "var(--primary-color-2-transparent)"
        }
    })


}