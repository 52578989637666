
import { RefLanguage } from '../hooks/LanguageHooks';
import { buildPerceptualSurveyExport, buildPerceptualSurveyFilename, encodeHealthFormId } from '../utils/perceptualSurveyFunctions';
import { HealthFormProgress } from '../hooks/PerceptualSurveyHooks';
import * as libCon from "./../community-hats-js-library/Constants"
import * as locCon from "./../LocalConstants"


import CustomText from '../elements/CustomText';

import { uploadToBucket } from '../community-hats-js-library/utils/AWSFunctions';
import Header from '../components/Header';
import { GenericSurveyProgressComponentAndExport, GenericSurveyFormStructureButton, GenericSurveyQuestions } from '../elements/SurveyElements';





// export const HealthFormQuestionsOld = () => {

//   const healthFormConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY

//   const healthFormStatus = RefStoredValue(locCon.HEALTH_FORM_CONFIGURATION_STATUS)

//   const i18n = RefLanguage()

//   const restartProcess = (showMessage = true) => {
//     if (healthFormConfiguration !== null && healthFormConfiguration !== undefined)
//       RestartHealthForm(healthFormConfiguration[libCon.SURVEY_STRUCTURE].map(q => encodeHealthFormId(q[libCon.SURVEY_QUESTION_ID])))

//     if (showMessage)
//       showNotification(i18n.t("healthFormRestarted"))

//   }


//   return (
//     <div>
//       {healthFormStatus !== libCon.STATUS_NOT_STARTED
//         ? <div>
//           <div className='horizontalLine' />
//           <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: 6 }}>{i18n.t("healthFormInstructions")}</CustomText>
//           <div style={{ marginBottom: "1vh", marginTop: "1vh" }}>
//             <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartSurvey")}</CustomButton>
//           </div>
//           <div className='horizontalLine' />
//           {
//             healthFormConfiguration[libCon.SURVEY_STRUCTURE].map((conf, i) =>
//               <div key={conf[libCon.SURVEY_QUESTION_ID]}>
//                 <SurveyComponent configuration={conf} position={i} encoder={encodeHealthFormId} />
//                 <div className='horizontalLine' />
//               </div>)
//           }
//         </div>
//         : <div></div>
//       }
//     </div>)

// }


// export const HealthFormProgressComponentAndExportOld = () => {

//   const [totalQuestions, answeredQuestions] = HealthFormProgress()

//   const i18n = RefLanguage()

//   const navigate = useNavigate();

//   const [canExport, setCanExport] = useState(() => totalQuestions !== null && totalQuestions <= answeredQuestions)

//   const perceptualSurveyConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY

//   const [formPublishedStatus, setFormPublishedStatus] = StateStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS)
//   const [formStatus, setFormStatus] = StateStoredValue(locCon.HEALTH_FORM_CONFIGURATION_STATUS)


//   const [hasError, setHasError] = useState(false)

//   useEffect(() => {
//     setFormPublishedStatus(libCon.STATUS_NOT_STARTED)
//   }, [setFormPublishedStatus])



//   useEffect(() => {
//     setCanExport(totalQuestions !== null && totalQuestions <= answeredQuestions)
//   }, [totalQuestions, answeredQuestions])


//   const localExport = async () => {


//     setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_LOADING)


//     // Builds
//     const toUpload = buildPerceptualSurveyExport()
//     const fileName = buildPerceptualSurveyFilename()

//     let sewa_id = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
//     if (sewa_id === null)
//       sewa_id = libCon.MISSING

//     // Exports
//     const reponse = await uploadToBucket(libCon.PERCEPTUAL_SURVEY_FOLDER, sewa_id, fileName, toUpload)

//     if (reponse === libCon.OK) {
//       setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_OK)

//       if (perceptualSurveyConfiguration !== null && perceptualSurveyConfiguration !== undefined)
//         RestartHealthForm(perceptualSurveyConfiguration[libCon.SURVEY_STRUCTURE].map(q => encodeHealthFormId(q[libCon.SURVEY_QUESTION_ID])))

//       setFormStatus(libCon.STATUS_NOT_STARTED)
//       setHasError(false)
//     }
//     else {
//       setHasError(true)
//       setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_ERROR)

//     }

//   }


//   const STATE_NOT_STARTED = "STATE_NOT_STARTED"
//   const STATE_LOADING_FORM = "STATE_LOADING_FORM"
//   const STATE_ANSWERING = "STATE_ANSWERING"
//   const STATE_SENDING_ANSWERS = "STATE_SENDING_ANSWERS"
//   const STATE_COMPLETED = "STATE_COMPLETED"
//   const STATE_FAILED_TO_UPLOAD = "STATE_FAILED_TO_UPLOAD"


//   const [currentState, setCurrentState] = useState(() => STATE_NOT_STARTED)


//   useEffect(() => {

//     if (formStatus === libCon.STATUS_NOT_STARTED) {
//       if (formPublishedStatus === libCon.STATUS_OK)
//         setCurrentState(STATE_COMPLETED)
//       else
//         setCurrentState(STATE_NOT_STARTED)

//     }
//     else {
//       if (formPublishedStatus === libCon.STATUS_LOADING)
//         setCurrentState(STATE_SENDING_ANSWERS)
//       else {
//         if (hasError)
//           setCurrentState(STATE_FAILED_TO_UPLOAD)
//         else
//           setCurrentState(STATE_ANSWERING)
//       }


//     }

//   }, [perceptualSurveyConfiguration, formPublishedStatus, hasError, canExport, formStatus])




//   // Component has four states

//   switch (currentState) {
//     case STATE_NOT_STARTED:
//       return (<CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("pleaseLoadHealthForm")}</CustomText>)
//     case STATE_COMPLETED:
//       return (
//         <div className="verticalSection" style={{ marginTop: 25 }}>
//           <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("healthFormSubmitted")}</CustomText>
//           <CustomButton type={locCon.PRIMARY_BUTTON} style={{ marginTop: 10 }} onClick={() => navigate(-1)}>
//             {i18n.t("goBack")}
//           </CustomButton>
//         </div>)
//     case STATE_LOADING_FORM:
//       return (<div></div>)
//     case STATE_SENDING_ANSWERS:
//       return (<div className="verticalSection" >
//         <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
//         <CustomSpin type={locCon.BUTTON_SPINNER} />
//         <CustomText type={locCon.DANGER_TEXT}></CustomText>
//       </div>)
//     case STATE_ANSWERING:
//       return (<div className="verticalSection" style={{ marginBottom: 5 }}>
//         <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
//         <CustomButton type={canExport ? locCon.PRIMARY_BUTTON : locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} onClick={() => canExport ? localExport() : showNotification(i18n.t("pleaseFillAllQuestions"))}>
//           {i18n.t("sendHealthForm")}
//         </CustomButton>
//         <CustomText type={locCon.DANGER_TEXT}></CustomText>

//       </div>)
//     case STATE_FAILED_TO_UPLOAD:
//       return (<div className="verticalSection" style={{ marginBottom: 5 }}>
//         <CustomText type={locCon.SUCCESS_TEXT}>{`Total answered questions: ${answeredQuestions} of ${totalQuestions}`}</CustomText>
//         <CustomButton type={canExport ? locCon.PRIMARY_BUTTON : locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} onClick={() => canExport ? localExport() : showNotification(i18n.t("pleaseFillAllQuestions"))}>
//           {i18n.t("sendHealthForm")}
//         </CustomButton>
//         <CustomText type={locCon.DANGER_TEXT}>{i18n.t("errorSendingHealthForm")}</CustomText>
//       </div>)
//     default:
//       return (<div></div>);
//   }



// }


// export const LoadHealthFormStructureButtonOld = () => {

//   const i18n = RefLanguage()

//   const healthFormStatus = RefStoredValue(locCon.HEALTH_FORM_CONFIGURATION_STATUS)

//   const loadHealthForm = async () => {
//     setStoredValue(locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS, libCon.STATUS_NOT_STARTED)
//     setStoredValue(locCon.HEALTH_FORM_CONFIGURATION_STATUS, libCon.STATUS_OK)

//   }

//   return (
//     <div className='verticalSection'>
//       <CustomText type={locCon.SUCCESS_TEXT}>{`${i18n.t("surveyVersion")}: ${libCon.LATEST_PERCEPTUAL_SURVEY[libCon.SURVEY_METADATA][libCon.SURVEY_VERSION]}`}</CustomText>

//       <div className='verticalSection' style={{ marginTop: "1vh" }} >
//         {
//           healthFormStatus === libCon.STATUS_NOT_STARTED
//             ? <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => loadHealthForm()}>{i18n.t("loadHealthForm")}</CustomButton>
//             : <div></div>
//         }
//       </div>
//     </div>
//   )
// }






export const HealthSurveyQuestions = () => {


  const i18n = RefLanguage()


  const encoder = encodeHealthFormId
  const surveyConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY
  const configurationStatusId = locCon.HEALTH_FORM_CONFIGURATION_STATUS
  const instructionsText = i18n.t("healthFormInstructions")

  return (<GenericSurveyQuestions encoder={encoder} surveyConfiguration={surveyConfiguration} configurationStatusId={configurationStatusId} instructionsText={instructionsText} />)
}



export const HealthFormProgressComponentAndExport = () => {

  const [totalQuestions, answeredQuestions] = HealthFormProgress()
  const surveyConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY
  const sendingStatusId = locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS
  const configurationStatusId = locCon.HEALTH_FORM_CONFIGURATION_STATUS

  const buildExport = buildPerceptualSurveyExport

  const buildFilename = buildPerceptualSurveyFilename

  const uploadSurvey = (sewa_id, fileName, toUpload) => uploadToBucket(libCon.PERCEPTUAL_SURVEY_FOLDER, sewa_id, fileName, toUpload)

  const encoder = encodeHealthFormId


  return (
    <GenericSurveyProgressComponentAndExport surveyConfiguration={surveyConfiguration}
      totalQuestions={totalQuestions}
      answeredQuestions={answeredQuestions}
      sendingStatusId={sendingStatusId}
      configurationStatusId={configurationStatusId}
      buildExport={buildExport}
      buildFilename={buildFilename}
      uploadSurvey={uploadSurvey}
      encoder={encoder} />
  )


}





export const HealthLoadFormStructureButton = () => {

  const configurationStatusId = locCon.HEALTH_FORM_CONFIGURATION_STATUS
  const sendingStatusId = locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS
  const surveyConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY



  return (<GenericSurveyFormStructureButton surveyConfiguration={surveyConfiguration} configurationStatusId={configurationStatusId} sendingStatusId={sendingStatusId} />)


}



export function PerceptualSurvey() {

  const i18n = RefLanguage()

  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={true} />

        <div className='verticalSection' style={{ marginBottom: 25 }}>

          <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1.5vh" }}>{i18n.t("perceptualSurvey")}</CustomText>

          <HealthLoadFormStructureButton />
          <HealthSurveyQuestions />
          <HealthFormProgressComponentAndExport />
        </div>
      </div>
    </div>
  );
}

export default PerceptualSurvey






