// Authentication Hooks
import { useEffect, useState } from "react"
import * as locCon from "../LocalConstants"
import { getStoredValue, RefStoredValue } from "./StoreHooks"
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions"
import { differenceInMinutes } from "date-fns"

export const isLoggedIn = () => {

    const lastLoggedIn = getStoredValue(locCon.LAST_LOG_IN)

    return (!isNullOrUndefined(lastLoggedIn)
        && differenceInMinutes(new Date(), new Date(lastLoggedIn)) < locCon.MAX_IDLE_MINUTES)
}

export const RefIsLoggedIn = () => {
    const lastLoggedIn = RefStoredValue(locCon.LAST_LOG_IN)

    const [loggedIn, setLoggedIn] = useState(() => isLoggedIn())


    useEffect(() => {

        let newIsLogIn = isLoggedIn()

        if (newIsLogIn) {
            setTimeout(() => {
                setLoggedIn(true)
            }, 1000);
        }
        else
            setLoggedIn(false)



    }, [lastLoggedIn])


    // Checks every minute
    useEffect(() => {
        // Function to run every minute
        const runEveryMinute = () => {
            setLoggedIn(isLoggedIn())
        };

        // Set the interval to run every 60,000 ms (1 minute)
        const intervalId = setInterval(runEveryMinute, 60000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return loggedIn


}