import { Button, Popconfirm } from 'antd'
import React from 'react'
import * as locCon from "../LocalConstants"
import { RefLanguage } from '../hooks/LanguageHooks'
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'
import { ModalHook } from '../hooks/ModalHooks'


const globalButtonStyles = {
    textDecoration: "none"
}

function CustomButton({ type, ...props }) {

    let { style, ...otherProps } = props
    if (isNullOrUndefinedOrEmpty(style))
        style = {}

    switch (type) {
        case locCon.PRIMARY_BUTTON_DISSABLED_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-4)',
                borderColor: 'var(--primary-color-4)',
                color: "#111111",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "2vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.PRIMARY_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "2vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.DANGER_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-2)',
                borderColor: 'var(--primary-color-2)',
                color: "#ffffff",
                width: "80%",
                marginLeft: "10%",
                marginRight: "10%",
                marginBottom: "2vh",
                ...style
            }}
                {...otherProps} />)

        case locCon.SAVE_EDIT_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.SAVE_EDIT_BUTTON_DISABLED:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-4)',
                borderColor: 'var(--primary-color-4)',
                color: "#111111",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.MODULE_REGULAR_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-3)',
                borderColor: 'var(--primary-color-3)',
                color: "#ffffff",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.MODULE_CANCEL_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: "#000000",
                color: "#000000",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)

        case locCon.LANGUAGE_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: 'var(--primary-color-5)',
                color: "#000000",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.SELECTED_LANGUAGE_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: 'var(--primary-color-3)',
                color: "#000000",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                borderWidth: 3,
                ...style
            }}
                {...otherProps} />)
        case locCon.LIKERT_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: 'var(--primary-color-5)',
                color: "#000000",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                ...style
            }}
                {...otherProps} />)
        case locCon.SELECTED_LIKERT_BUTTON:
            return (<Button style={{
                ...globalButtonStyles,
                backgroundColor: 'var(--primary-color-5)',
                borderColor: 'var(--primary-color-3)',
                color: "#000000",
                width: "50px",
                marginLeft: "1%",
                marginRight: "1%",
                borderWidth: 3,
                ...style
            }}
                {...otherProps} />)

        default:
            return (<Button  {...props} />)
    }


}

export const CustomPrimaryButtonWithDisability = ({ isDisabled, title, description, ...props }) => {

    const i18n = RefLanguage()

    return (
        isDisabled
            ? <Popconfirm style={{ maxWidth: "100%" }} okText={i18n.t("ok")} title={title} description={description} showCancel={false} placement="top">
                <CustomButton type={locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} {...props} onClick={() => false} />
            </Popconfirm>
            : <CustomButton type={locCon.PRIMARY_BUTTON} {...props} />
    )

}


export const CustomDangerButtonWithDisability = ({ isDisabled, title, description, ...props }) => {

    const i18n = RefLanguage()

    return (
        isDisabled
            ? <Popconfirm style={{ maxWidth: "100%" }} okText={i18n.t("ok")} title={title} description={description} showCancel={false} placement="top">
                <CustomButton type={locCon.PRIMARY_BUTTON_DISSABLED_BUTTON} {...props} onClick={() => false} />
            </Popconfirm>
            : <CustomButton type={locCon.DANGER_BUTTON} {...props} />
    )

}



export const CustomButtonWithWarining = ({ title, description, okButtonText, onClick, ...props }) => {

    const i18n = RefLanguage()

    // Modal
    const [modal, openModal] = ModalHook()

    const confirmOnClick = () => {



        openModal(title, description, [
            {
                text: i18n.t("cancel"),
                onClick: () => false,
                type: locCon.MODULE_CANCEL_BUTTON,
            },
            {
                text: isNullOrUndefined(okButtonText) ? i18n.t("continue") : okButtonText,
                onClick: () => onClick(),
                type: locCon.MODULE_REGULAR_BUTTON
            },
        ]);



    }

    return (
        <>
            {modal}
            <CustomButton onClick={() => confirmOnClick()} {...props} />
        </>


    )

}

export default CustomButton