import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as locCon from "../LocalConstants"
import CustomButton from './CustomButton';

function BackButton() {

  const navigate = useNavigate();

  return (
    <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => navigate(-1)}><ArrowLeftOutlined /></CustomButton>
  )
}

export default BackButton

