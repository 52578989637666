import React from 'react'
import CustomButton from '../elements/CustomButton'
import { RefLanguage } from '../hooks/LanguageHooks'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { setStoredValue } from '../hooks/StoreHooks'
import { getClipboardValue } from '../utils/generalFunctions'
import { NewIcon } from '../elements/CustomIcons'
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions'

function PasteFitbitAPIValues({ checkboxId = null }) {

    const i18n = RefLanguage()

    const pasteValues = async () => {

        let vals = await getClipboardValue()
        const splitArray = vals.split(libCon.FITBIT_API_SEP)

        setStoredValue(locCon.FITBIT_TOKEN, splitArray[0])
        setStoredValue(locCon.FITBIT_REQUEST_ID, splitArray[1])

        if (!isNullOrUndefined(checkboxId))
            setStoredValue(checkboxId, true)


    }


    return (
        <CustomButton type={locCon.PRIMARY_BUTTON} onClick={pasteValues}>
            <NewIcon color={"var(--primary-color-5)"} />{i18n.t("pasteBothValues")}
        </CustomButton>
    )
}

export default PasteFitbitAPIValues