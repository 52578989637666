import React from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { RefStoredBooleanValue, setATOField, setMultipleValues, setStoredValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { EndAndRemovePlacementButton, SyncATOButton } from '../elements/SyncATOButton';
import { WearableSerialExtractorForBundleWearable } from '../components/WearableSerialExtractor';
import CompletionStatusText from '../elements/CompletionStatusText';

function ReplaceWearable() {

    const i18n = RefLanguage()


    // Local checkboxes
    const isPlacementRemoved = RefStoredBooleanValue(locCon.RW_END_AND_REMOVE_WEARABLE_PLACEMENT)


    const allCheckboxes = [
        locCon.RW_COLLECT_DATA,
        locCon.RW_END_AND_REMOVE_WEARABLE_PLACEMENT,
        locCon.RW_REMOVE_CURRENT_FITBIT_DEVICE,
        locCon.RW_SET_UP_NEW_DEVICE,
        locCon.RW_GET_WEARABLE_SERIAL,
        locCon.RW_LINK_WEARABLE_SERIAL,
        locCon.RW_WEARABLE_PLACEMENT,
        locCon.RW_UPDATE_BUNDLE_TO_DATABASE,
    ]




    const restartProcess = () => {

        let checkBoxValues = {}

        allCheckboxes.forEach(k => checkBoxValues[k] = false)

        setMultipleValues(checkBoxValues)


    }


    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("replaceWearable")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToReplaceWearable")}</CustomText>

                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className='horizontalLine' />

                {/* Collect Data and  Check bundel*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckWearable")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckWearableText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RW_COLLECT_DATA, [libCon.TEXT]: i18n.t("collecWearabletData") }} />

                <div className='horizontalLine' />

                {/* End and remove wearable placement from Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endWearablPalcement")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endWearablPalcementText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RW_END_AND_REMOVE_WEARABLE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("endWearablPalcement"),
                    [libCon.CONTAINER]: <EndAndRemovePlacementButton placementATOid={locCon.AT_OBJECT_WEARABLE_PLACEMENT} onClickCleanup={(val) => { setStoredValue(locCon.RW_END_AND_REMOVE_WEARABLE_PLACEMENT, val); setATOField(locCon.AT_OBJECT_WEARABLE, libCon.ATF_SERIAL, null) }} forceSuccessText={isPlacementRemoved} />
                }} />
                <div className="horizontalLine" />

                {/* Fitbit set up */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("fitBitSetUp")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("fitBitSetText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RW_REMOVE_CURRENT_FITBIT_DEVICE, [libCon.TEXT]: i18n.t("removeCurrentFitbit") }} />
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.RW_SET_UP_NEW_DEVICE, [libCon.TEXT]: i18n.t("addNewFitbit") }} />
                <CheckboxGroup mainCheckbox={{
                    [libCon.ID]: locCon.RW_GET_WEARABLE_SERIAL,
                    [libCon.TEXT]: i18n.t("getWearableSerialNumber"),
                    [libCon.CONTAINER]: <div style={{ marginBottom: 10 }}>
                        <WearableSerialExtractorForBundleWearable enableCopy={false} />
                    </div>
                }} />
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RW_LINK_WEARABLE_SERIAL,
                    [libCon.TEXT]: i18n.t("linkWearableSerialNumber"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_WEARABLE} atoBehavior={libCon.ATO_BEHAVIOR_PULL} onClickCleanup={(val) => setStoredValue(locCon.RW_LINK_WEARABLE_SERIAL, val)} />
                }} />

                <div className="horizontalLine" />

                {/* Add / Sync Bundle to database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateBundleText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RW_UPDATE_BUNDLE_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("updateBundle"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_BUNDLE} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RW_UPDATE_BUNDLE_TO_DATABASE, val)} />
                }} />

                <div className="horizontalLine" />


                {/* Add wearable placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearablePlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("wearablePlacementToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.RW_WEARABLE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("wearablePlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_WEARABLE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.RW_WEARABLE_PLACEMENT, val)} />
                }} />
                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />


            </div>
        </div>
    );
}

export default ReplaceWearable