import React, { useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton from '../elements/CustomButton';
import { getKeysOfATOSet, RefStoredMultipleValues, setATOField, setMultipleValues, setStoredValue, StateStoredBooleanValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { EndAndRemovePlacementButton, EndAndRemovePlacementInsideSetButton, SyncATOButton } from '../elements/SyncATOButton';
import CompletionStatusText from '../elements/CompletionStatusText';
import { useEffect } from 'react';
import { isSensorPlacementActive } from '../hooks/BundleDeviceHooks';
import SensorPlacementSetViewer from '../components/SensorPlacementSetViewer';
import { LocationExtractorFroATOFields } from '../components/LocationExtractor';
import { NewIcon } from '../elements/CustomIcons';
import { SimpleStoreCopySelectionFieldFromATO } from '../elements/SimpleStoreCopySelectionField';
import { SimpleStoreCopyTextAreaFromATO } from '../elements/SimpleStoreCopyTextArea';

const initialCheckboxes = [
    locCon.CH_COLLECT_DATA,
    locCon.CH_REMOVE_PARTICIPANT_PLACEMENT,
    locCon.CH_EXTRACT_LOCATIONS_COORDINATES,
    locCon.CH_ADD_LOCATION_TO_DATABASE,
    locCon.CH_PARTICIPANT_PLACEMENT,
]

function ChangeHouse() {

    const i18n = RefLanguage()

    const [activeKeys, setActiveKeys] = useState(() => getKeysOfATOSet().filter(k => isSensorPlacementActive(k)))

    // Finished Ending Placements
    //const [sensorPlacementEnded, setSensorPlacementEnded] = useState(() => Object.fromEntries(activeKeys.map(k => [k, false])))
    const sensorPlacementEnded = RefStoredMultipleValues(activeKeys.map(positionId => locCon.getCHRSCheckBoxId(positionId)))
    const [participantPlacementEnded, setParticipantPlacementEnded] = StateStoredBooleanValue(locCon.CH_REMOVE_PARTICIPANT_PLACEMENT)


    const [allCheckboxes, setAllCheckboxes] = useState([...initialCheckboxes])

    useEffect(() => {

        let keys = getKeysOfATOSet()

        let newCheckboxes = [...initialCheckboxes,
        ...activeKeys.map(positionId => locCon.getCHRSCheckBoxId(positionId)),
        ...keys.map(positionId => locCon.getASHPCheckBoxId(positionId))]

        setAllCheckboxes(newCheckboxes)

    }, [activeKeys])



    const restartProcess = () => {

        let newActiveKeys = getKeysOfATOSet().filter(k => isSensorPlacementActive(k))

        setActiveKeys(newActiveKeys)

        let checkBoxValues = {}


        newActiveKeys.forEach(positionId => checkBoxValues[locCon.getCHRSCheckBoxId(positionId)] = false)
        allCheckboxes.forEach(k => checkBoxValues[k] = false)

        setMultipleValues(checkBoxValues)

        resetHouseValuesAndSensorPlacementCheckboxes()

    }

    const resetHouseValuesAndSensorPlacementCheckboxes = () => {


        // House
        setATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_ADDRESS, null)
        setATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_AREA, null)
        setATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_LATITUDE, null)
        setATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_LONGITUDE, null)
        setATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_ACCURACY, null)


        // Sensor Pacements
        let keys = getKeysOfATOSet()
        keys.forEach(positionId => setStoredValue(locCon.getASHPCheckBoxId(positionId), false))


    }




    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("changeHouse")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToChangeHouse")}</CustomText>

                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className='horizontalLine' />

                {/* Collect Data and  Check bundel*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckSensors")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckSensorsText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.CH_COLLECT_DATA, [libCon.TEXT]: i18n.t("collectAndCheckSensors") }} />

                <div className='horizontalLine' />

                {/* Remove Sensor placements to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endSensorPalcements")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endSensorPalcementsText")}</CustomText>

                {activeKeys.length === 0
                    ? <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("noActivePlacementsContinue")}</CustomText>
                    : <div></div>}

                {
                    activeKeys.map((positionId, i) =>



                        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-2)", marginTop: 7, marginBottom: 7 }}>

                            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("sensorPlacement") + " " + (i + 1)}</CustomText>

                            <CheckboxGroup disableClick={true} mainCheckbox={{
                                [libCon.ID]: locCon.getCHRSCheckBoxId(positionId),
                                [libCon.TEXT]: i18n.t("endSensorPlacement"),
                                [libCon.CONTAINER]:
                                    <EndAndRemovePlacementInsideSetButton positionId={positionId}
                                        placementATOid={locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT}
                                        // onClickCleanup={(val) => { setStoredValue(locCon.getCHRSCheckBoxId(positionId), val); setSensorPlacementEnded({ ...sensorPlacementEnded, [positionId]: val }); setStoredValue(locCon.getASHPCheckBoxId(positionId), !val) }}
                                        onClickCleanup={(val) => { setStoredValue(locCon.getCHRSCheckBoxId(positionId), val); setStoredValue(locCon.getASHPCheckBoxId(positionId), !val) }}
                                        forceSuccessText={sensorPlacementEnded[positionId]} />
                            }} />

                        </div>

                    )
                }


                <div className='horizontalLine' />


                {/* Remove Participant placements to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("endParticipantPlacement")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("endParticipantPlacementText")}</CustomText>

                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.CH_REMOVE_PARTICIPANT_PLACEMENT,
                    [libCon.TEXT]: i18n.t("endParticipantPlacement"),
                    [libCon.CONTAINER]:
                        <EndAndRemovePlacementButton
                            placementATOid={locCon.AT_OBJECT_PARTICIPANT_PLACEMENT}
                            onClickCleanup={(val) => { setParticipantPlacementEnded(val); resetHouseValuesAndSensorPlacementCheckboxes() }}
                            forceSuccessText={participantPlacementEnded} />
                }} />

                <div className="horizontalLine" />

                {/* Add house into database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("houseToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("houseToDataBaseText")}</CustomText>
                <CheckboxGroup
                    mainCheckbox={{
                        [libCon.ID]: locCon.CH_EXTRACT_LOCATIONS_COORDINATES,
                        [libCon.TEXT]: i18n.t("extractLocationCoordinatesAndAdress"),
                        [libCon.CONTAINER]:
                            <div>
                                <SimpleStoreCopySelectionFieldFromATO atoId={locCon.AT_OBJECT_HOUSE} fieldId={libCon.ATF_AREA} title={<div><NewIcon />{i18n.t("area")}</div>} options={libCon.AREAS} optionsTextDict={Object.fromEntries(libCon.AREAS.map(k => [k, k]))} />
                                <SimpleStoreCopyTextAreaFromATO title={<div> <NewIcon />{i18n.t("address")}</div>} atoId={locCon.AT_OBJECT_HOUSE} fieldId={libCon.ATF_ADDRESS} />
                                <LocationExtractorFroATOFields atoId={locCon.AT_OBJECT_HOUSE} latId={libCon.ATF_LATITUDE} lonId={libCon.ATF_LONGITUDE} accId={libCon.ATF_ACCURACY} enableCopy={false} onClickCleanup={(lat, lon, acc) => setStoredValue(locCon.CH_EXTRACT_LOCATIONS_COORDINATES, acc <= libCon.IDEAL_ACCURACY)} />

                            </div>
                    }}
                />

                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.CH_ADD_LOCATION_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("houseToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_HOUSE} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.CH_ADD_LOCATION_TO_DATABASE, val)} />
                }} />

                <div className="horizontalLine" />

                {/* Add participant placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("participantPlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("participantPlacementToDataBaseText")}</CustomText>

                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.CH_PARTICIPANT_PLACEMENT,
                    [libCon.TEXT]: i18n.t("participantPlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_PARTICIPANT_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.CH_PARTICIPANT_PLACEMENT, val)} />
                }}
                />

                <div className="horizontalLine" />


                {/* set up each one of the bundle sensors*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("deploySensors")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("deployBundleDevicesText")}</CustomText>
                <SensorPlacementSetViewer />


                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} extraMessage={i18n.t("collectDataAndCheckSensors")} />

                <div className="horizontalLine" />


            </div>
        </div>
    );
}

export default ChangeHouse