import Header from '../components/Header';
import FitbitTokenUpdater from '../components/FitbitTokenUpdater';


export function ToolFitbitTokenUpdater() {


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>
        <Header long={true} />
        <FitbitTokenUpdater />
      </div>
    </div>
  );
}

export default ToolFitbitTokenUpdater


