import React from "react";

import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { RefATOField, setATOField, setATOFieldForAllInsideSet, StateStoredBooleanValue } from "../hooks/StoreHooks";
import { RefLanguage } from "../hooks/LanguageHooks";
import { ModalHook } from "../hooks/ModalHooks";
import CustomButton from "../elements/CustomButton";
import { SimpleStoreCopyField } from "../elements/SimpleStoreCopyField";
import { SimpleStoreCopySelectionField } from "../elements/SimpleStoreCopySelectionField";

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const isValidCode = (input) => {

  if (input === null || input === undefined)
    return false
  // Check if the input length is 2
  if (input.length !== 2) {
    return false;
  }

  // Regular expression to check the pattern
  const pattern = /^[A-Z][0-9]$/;

  // Test the input against the pattern
  return pattern.test(input);
}

export function TagGenerator() {

  // Modal
  const [modal, openModal] = ModalHook()


  const i18n = RefLanguage()


  const tagCode = RefATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_CODE)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(libCon.ATF_TAG_CODE + locCon.EDITING_VALUE)

  const tagColor = RefATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_COLOR)


  const setTagCode = (newTag) => {

    // Bundle
    setATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_CODE, newTag)

    // Dependencies
    setATOField(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.ATF_TAG_CODE, newTag)
    setATOField(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.ATF_TAG_CODE, newTag)
    setATOFieldForAllInsideSet(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_CODE, newTag)

  }

  const setTagColor = (newColor) => {
    // Bundle
    setATOField(locCon.AT_OBJECT_BUNDLE, libCon.ATF_TAG_COLOR, newColor)

    // Dependencies
    setATOField(locCon.AT_OBJECT_PHONE_PLACEMENT, libCon.ATF_TAG_COLOR, newColor)
    setATOField(locCon.AT_OBJECT_WEARABLE_PLACEMENT, libCon.ATF_TAG_COLOR, newColor)
    setATOFieldForAllInsideSet(locCon.AT_OBJECT_SENSORS_HOUSE_PLACEMENT, libCon.ATF_TAG_COLOR, newColor)
  }




  const askGenerateValues = () => {


    if (tagCode !== null && tagCode !== undefined) {

      openModal(i18n.t("regenerateValues"), i18n.t("regenerateValuesLong"), [
        {
          text: i18n.t("cancel"),
          onClick: () => false,
          type: locCon.MODULE_CANCEL_BUTTON,
        },
        {
          text: i18n.t("generate"),
          onClick: () => generateValues(),
          type: locCon.MODULE_REGULAR_BUTTON
        },
      ]);

    }
    else
      generateValues()

  }

  const generateValues = () => {

    // Generate a random capital letter
    const randomLetter = letters[Math.floor(Math.random() * letters.length)];

    // Generate a random number from 0 to 9
    const randomNumber = Math.floor(Math.random() * 10);

    const randomColor = libCon.COLORS[Math.floor(Math.random() * libCon.COLORS.length)]

    setTagColor(randomColor)
    setTagCode(`${randomLetter}${randomNumber}`)

    setEditingValue(false)

  }


  return (

    <div className="verticalSection">
      {modal}
      <CustomButton type={locCon.PRIMARY_BUTTON} onClick={askGenerateValues}>{i18n.t("generateValues")}</CustomButton>
      <SimpleStoreCopyField value={tagCode} setValue={setTagCode} title={i18n.t("bundleTagCode")} checkFunction={isValidCode} editingValue={editingValue} setEditingValue={setEditingValue} />
      <SimpleStoreCopySelectionField value={tagColor} setValue={setTagColor} title={i18n.t("bundleTagColor")} options={libCon.COLORS} optionsTextDict={libCon.COLORS.reduce((dict, item) => { dict[item] = i18n.t(item); return dict; }, {})} />
    </div>
  );
}



// Define default props
TagGenerator.defaultProps = {
  checkFunction: (val) => val !== null && val !== undefined && val !== "",
  maxLength: 25,
  infoText: null
};

export default TagGenerator;





