import * as libCon from '../Constants';
import { isNullOrUndefinedOrEmptyOrMissing } from './generalFunctions';



export const buildIntakeSurveyLink = (participantId, sewaId, name, lastName) => {

    let baseLink = libCon.INTAKE_FORM_LINK

    let vals = []

    if (!isNullOrUndefinedOrEmptyOrMissing(sewaId))
        vals.push(`${libCon.INTAKE_FORM_FIELD_ID_SEWA_ID}=${sewaId}`)
    if (!isNullOrUndefinedOrEmptyOrMissing(name))
        vals.push(`${libCon.INTAKE_FORM_FIELD_ID_NAME}=${name}`)
    if (!isNullOrUndefinedOrEmptyOrMissing(lastName))
        vals.push(`${libCon.INTAKE_FORM_FIELD_ID_LAST_NAME}=${lastName}`)

    if (vals.length > 0)
        baseLink += "?" + vals.join("&")

    return baseLink

}