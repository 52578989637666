import React from "react";
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomText from "./CustomText";
import CustomButton from "./CustomButton";
import { CheckboxDefaultValue } from "./CustomCheckBox";
import { StateStoredValue } from "../hooks/StoreHooks";


export function SimpleLikertScaleFromId({ valueID, ...props }) {
  const [value, setValue] = StateStoredValue(valueID)

  return (<SimpleLikertScale value={value} setValue={setValue} {...props} />)
}


export function SimpleLikertScale({ value, setValue, title, infoText, options, optionText, fontSize, includeCheckbox = false, defaultValue = libCon.DEFAULT_LIKERT, checkboxText }) {

  const i18n = RefLanguage()


  return (
    <div className="verticalSection">
      {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
      {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
      <div style={styles.likertSection}>
        {
          options.map((val, i) => {
            let buttonType = value !== null && value !== undefined && value[libCon.LIKERT_STRING] === val ? locCon.SELECTED_LIKERT_BUTTON : locCon.LIKERT_BUTTON

            return (
              <CustomButton type={buttonType} key={val} onClick={() => setValue({ [libCon.LIKERT_STRING]: val, [libCon.LIKERT_NUMERIC]: i })}>{optionText[i]}</CustomButton>
            )
          })
        }
      </div>
      {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} isDefault={(newVal, __) => newVal !== null && newVal !== undefined && newVal[libCon.LIKERT_STRING] === libCon.DEFAULT_LIKERT[libCon.LIKERT_STRING]} /> : <div></div>}
      <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{`${i18n.t("answer")}: ${value === null || value === undefined ? "----" : value[libCon.LIKERT_STRING]}`}</CustomText>
    </div>


  )
}




const styles = {
  likertSection: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-around",
    alignItems: 'center',
    alignContent: "stretch",
    marginBottom: 20,
    marginTop: 15,
    width: "100%"
  }
}
