import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import CustomButton from '../elements/CustomButton';


export function IntakeForm() {

    const i18n = RefLanguage()

    const restartProcess = () => {

        //TODO
    }

    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("intakeForm")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("instructionsForIntakeForm")}</CustomText>
                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className="horizontalLine" />

            </div>
        </div>
    );
}

export default IntakeForm


