import React, { useEffect, useState } from "react";

import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { RefATOField, RefStoredValue, setATOField, setStoredValue, StateStoredBooleanValue } from "../hooks/StoreHooks";
import { RefLanguage } from "../hooks/LanguageHooks";

import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { testFitbitApi } from "../community-hats-js-library/utils/fitbitFunctions";
import CustomText from "../elements/CustomText";
import CustomSpin from "../elements/CustomSpin";
import { CustomPrimaryButtonWithDisability } from "../elements/CustomButton";
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";
import { add, formatISO } from 'date-fns';

export function TestFitibitApiButton({ checkboxId = null }) {

  const fitbitClient = libCon.FITBIT_FINAL_APPLICATION_ID

  const i18n = RefLanguage()

  // Values
  const token = RefStoredValue(locCon.FITBIT_TOKEN)
  const requestId = RefStoredValue(locCon.FITBIT_REQUEST_ID)

  const tokenSaved = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_TOKEN)
  const requestIdSaved = RefATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_REQUEST_ID)

  const [apiOk, setApiOk] = StateStoredBooleanValue(locCon.FITBIT_API_OK)


  const [testing, setTesting] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [warningText, setWarningText] = useState(() => null)



  useEffect(() => {

    if (isNullOrUndefined(token) || isNullOrUndefined(requestId)) {
      setDisabled(true)
      let missing = []
      if (isNullOrUndefined(token))
        missing.push(libCon.ATF_FITBIT_TOKEN)
      if (isNullOrUndefined(requestId))
        missing.push(libCon.ATF_FITBIT_REQUEST_ID)

      setWarningText(missing.join(", "))

      setApiOk(false)
    }
    else
      setDisabled(false)


  }, [token, requestId, tokenSaved, requestIdSaved, setApiOk])


  const testApi = async () => {

    setTesting(true)


    const code = await testFitbitApi(requestId, token)

    if (code === libCon.OK) {
      setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_TOKEN, token)
      setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_REQUEST_ID, requestId)
      setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_CLIENT_ID, fitbitClient)
      setATOField(locCon.AT_OBJECT_EMAIL_ACCOUNT, libCon.ATF_FITBIT_TOKEN_EXPIRY_DATE, formatISO(add(new Date(), { years: 1 })))

      setApiOk(true)

      setStoredValue(checkboxId, true)
    }
    else {
      showErrorDialogByCode(code)
      setStoredValue(checkboxId, false)
    }


    setTesting(false)


  }


  return (

    <div className="verticalSection">
      {
        apiOk === true
          ? <CustomText type={locCon.ELEMENT_TEXT}>{i18n.t("successFitbitAPI")}</CustomText>
          : testing === true
            ? <CustomSpin type={locCon.BUTTON_SPINNER} />
            : <CustomPrimaryButtonWithDisability isDisabled={disabled} title={i18n.t("missingValues")} description={warningText} onClick={() => testApi()}>
              {i18n.t("testApi")}
            </CustomPrimaryButtonWithDisability>
      }
    </div>
  );
}


export default TestFitibitApiButton;







