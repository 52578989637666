import React from "react";
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomText from "./CustomText";
import { CheckboxDefaultValue } from "./CustomCheckBox";
import { StateStoredValue } from "../hooks/StoreHooks";
import { Radio } from "antd";
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";


export function SimpleRadioButtonFromId({ valueID, ...props }) {
    const [value, setValue] = StateStoredValue(valueID)

    return (<SimpleRadioButton value={value} setValue={setValue} {...props} />)
}


export function SimpleRadioButton({ value, setValue, title, infoText, options, optionText, orientation = locCon.HORIZONTAL, fontSize, includeAnswer = true, includeCheckbox = false, defaultValue = libCon.DEFAULT_LIKERT, checkboxText }) {

    const i18n = RefLanguage()

    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}

            <div className="verticalSection" style={{ marginTop: "1.5vh" }}>
                <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} style={{ width: "100%" }} >
                    <div className={orientation === locCon.HORIZONTAL ? "horizontalSection" : "verticalSection"}>
                        {
                            options.map((val, i) => <Radio key={val} value={val}>{optionText[i]}</Radio>)
                        }
                    </div>
                </Radio.Group>
            </div>

            {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} isDefault={(newVal, __) => newVal !== null && newVal !== undefined && newVal[libCon.LIKERT_STRING] === libCon.DEFAULT_LIKERT[libCon.LIKERT_STRING]} /> : <div></div>}
            {includeAnswer ? <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{`${i18n.t("answer")}: ${value === defaultValue ? defaultValue : isNullOrUndefined(value) || !options.includes(value) ? "----" : optionText[options.indexOf(value)]}`}</CustomText> : <div></div>}
        </div>


    )
}




