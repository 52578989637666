import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import * as locCon from "../LocalConstants"
import CustomText from './CustomText'
import { StateStoredValue } from '../hooks/StoreHooks'


const globalCheckboxStyles = {
    marginRight: "5px"
}



function CustomCheckBox({ type, disableClick = false, ...props }) {

    let localStyles = { ...globalCheckboxStyles }

    switch (type) {
        case locCon.MAIN_PROGRESS_CHECKBOX:
            return (<Checkbox className={`mainProgressCheckbox ${disableClick ? 'disabled' : ''}`}
                style={{
                    ...localStyles,
                }}
                {...props} />)
        case locCon.SECONDARY_PROGRESS_CHECKBOX:
            return (<Checkbox className={`secondaryProgressCheckbox ${disableClick ? 'disabled' : ''}`}
                style={{ ...localStyles }}
                {...props} />)
        case locCon.DEFAULT_OPTION_CHECKBOX:
            return (<Checkbox className={`defaultProgressCheckbox ${disableClick ? 'disabled' : ''}`}
                style={{ ...localStyles }}
                {...props} />)

        default:
            return (<Checkbox  {...props} />)
    }


}

export default CustomCheckBox



export function CheckboxDefaultValueFromId({ valueID, ...props }) {


    const [value, setValue] = StateStoredValue(valueID)


    return (
        <CheckboxDefaultValue value={value} setValue={setValue} {...props} />
    );
}


export function CheckboxDefaultValue({ value, setValue, defaultValue, resetValue = null, setEditingValue = (val) => true, text, isDefault = (val, defaultVal) => val === defaultVal }) {


    const [selected, setSelected] = useState(() => isDefault(value, defaultValue))

    useEffect(() => {
        setSelected(isDefault(value, defaultValue))
    }, [value, defaultValue, isDefault])



    const setDefaultValue = (val) => {


        if (val) {
            setValue(defaultValue)
            setEditingValue(false)
        }
        else {

            setValue(resetValue)
            setEditingValue(true)
        }

    }

    return (
        <div className='horizontalSection' style={{ marginBottom: "2vh" }}>
            <div style={{ width: "75%", maxWidth: "75%", marginRight: "1vh", textAlign: "right" }}><CustomText onClick={() => setDefaultValue(!selected)} type={locCon.DEFAULT_INFO_TEXT}>{text}</CustomText></div>
            <CustomCheckBox type={locCon.DEFAULT_OPTION_CHECKBOX} checked={selected} onChange={(val) => setDefaultValue(val.target.checked)} />
        </div>

    );
}


