import React, { useEffect, useState } from 'react'
import CustomText from '../elements/CustomText'
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import { SyncATOButton } from '../elements/SyncATOButton';
import { RefLanguage } from '../hooks/LanguageHooks';
import { SimpleCopyFieldFromATO } from '../elements/SimpleStoreCopyField';
import { RefATOField } from '../hooks/StoreHooks';

function ParticipantIdUpdater() {

    const i18n = RefLanguage()

    const sewaId = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)

    const [message, setMessage] = useState(null)

    const [hasChecked, setHasChecked] = useState(false)




    useEffect(() => {

        if (sewaId === libCon.MISSING)
            setMessage(i18n.t("stillMissingSewaIdUpdated"))
        else
            setMessage(i18n.t("successSewaIdUpdated"))

    }, [sewaId, i18n])


    return (
        <div className='verticalSection'>
            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateSewaId")}</CustomText>
            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateSewaIdInfo")}</CustomText>
            <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_SEWA_ID} enableCopy={false} title={i18n.t("sewaId")} />
            <SyncATOButton atoId={locCon.AT_OBJECT_PARTICIPANT} atoBehavior={libCon.ATO_BEHAVIOR_PULL} becomeTextOnSuccess={false} onClickCleanup={() => setHasChecked(true)} />
            {
                hasChecked
                    ? <CustomText type={locCon.ELEMENT_TEXT}>{message}</CustomText>
                    : <div></div>
            }
        </div>
    )
}

export default ParticipantIdUpdater