import * as locCon from '../LocalConstants';
import { RefLanguage } from '../hooks/LanguageHooks';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';


export function Install() {

  const i18n = RefLanguage()


  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} />

        <div className='verticalSection' style={{ marginTop: "5vh" }}>
          <CustomText type={locCon.INSTRUCTIONS_TEXT} style={{ marginBottom: "1vh" }}>{i18n.t("installAppInstructions")}</CustomText>
          <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("installAppInstructions1")}</CustomText>
          <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("installAppInstructions2")}</CustomText>
          <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("installAppInstructions3")}</CustomText>
        </div>



      </div>
    </div>
  );
}




export default Install


