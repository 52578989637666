import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import { RefStoredValue, StateATOField } from '../hooks/StoreHooks';
import { RefLanguage } from '../hooks/LanguageHooks';
import { useEffect, useState } from 'react';
import CustomText from '../elements/CustomText';
import { SimpleCopyField, SimpleStoreCopyFieldFromId } from '../elements/SimpleStoreCopyField';




export function WearableSerialExtractorForBundleWearable({ enableCopy }) {

  const [serial, setSerial] = StateATOField(locCon.AT_OBJECT_WEARABLE, libCon.ATF_SERIAL)


  return (
    <WearableSerialExtractor serial={serial} setSerial={setSerial} urlStorageId={locCon.FITBIT_URL} enableCopy={enableCopy} />
  );
}


WearableSerialExtractorForBundleWearable.defaultProps = {
  enableCopy: true,
};


export function WearableSerialExtractor({ serial, setSerial, urlStorageId, enableCopy }) {

  const i18n = RefLanguage()

  const fitbitURL = RefStoredValue(urlStorageId)

  const [message, setMessage] = useState(i18n.t("pleaseAddURLtoExtract"))


  useEffect(() => {

    if (fitbitURL === null || fitbitURL === undefined)
      setMessage(i18n.t("pleaseAddURLtoExtract"))
    else {
      try {

        if (!fitbitURL.startsWith("https://www.fitbit.com/settings/device/tracker/"))
          throw new Error()

        let newSerial = fitbitURL.replace("https://www.fitbit.com/settings/device/tracker/", "")

        if (newSerial.length !== 12)
          throw new Error()

        const base = "ABCDEFGHIJKL"
        const template = "KLIJGHEFCDAB" // https://community.fitbit.com/t5/Other-Versa-Smartwatches/How-do-I-find-Versa-2-serial-number/m-p/5228127/highlight/true#M276217
        let unscrambledSerial = ""

        for (let i = 0; i < base.length; i++) {

          const char = base.charAt(i);
          const pos = template.indexOf(char);

          unscrambledSerial += newSerial[pos]

        }

        setMessage(null)
        setSerial(unscrambledSerial)

      } catch (error) {
        setMessage(i18n.t("badWearableURL"))
      }

    }

  }, [fitbitURL, setSerial, i18n])


  return (
    <div className='verticalSection'>
      <SimpleStoreCopyFieldFromId valueID={urlStorageId} title={i18n.t("fitbitURL")} placeHolder={i18n.t("fitbitURL")} />
      <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("wearableSerial")}</CustomText>
      {
        serial === null || serial === undefined
          ? <CustomText type={locCon.ELEMENT_TEXT}>{message}</CustomText>
          : <SimpleCopyField value={serial} enableCopy={enableCopy} />
      }
    </div>
  );
}


WearableSerialExtractor.defaultProps = {
  enableCopy: true,
};


